import {
  Email,
  Facebook,
  LocationOn,
  Phone,
  YouTube,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import images from "../../assets/images";

const Footer = () => {
  const quickLinks = [
    { name: "Home", route: "/" },
    { name: "Explorer's Guide", route: "/explorer-guide" },
    { name: "Treasure Chest", route: "/treasure-chest" },
    { name: "Client Management", route: "/sponsers" },
    { name: "About Us", route: "/about" },
    { name: "Reach Out", route: "/contact-us" },
  ];

  return (
    <footer
      style={{ background: "#f8fafc", padding: "40px 0" }}
      className="main_container"
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo and Description */}
          <Grid item xs={12} md={5}>
            <Link to="/">
              <img src={images.flogo} alt="logo" />
            </Link>
            <Typography className="f-16 color-para" mt={2}>
              At Centerboard Wellness, we provide personalized services tailored
              to help you navigate your unique journey toward growth and
              well-being.
            </Typography>
            <Typography
              variant="subtitle1"
              className="fw-600 text-black"
              my={2}
            >
              Stay connected—follow us for insights, tips, and inspiration on
              your journey.
            </Typography>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className="f_icon">
                <Facebook />
              </IconButton>
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className="f_icon">
                <XIcon />
              </IconButton>
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className="f_icon">
                <YouTube />
              </IconButton>
            </a>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={2}>
            <Typography className="f-16 fw-600 pb-1">Quick Links</Typography>
            {quickLinks.map((link) => (
              <Link
                key={link.name}
                to={link.route}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <Typography
                  className="f-16 pb-1"
                  mt={1}
                  sx={{ "&:hover": { color: "blue" } }}
                >
                  {link.name}
                </Typography>
              </Link>
            ))}
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} md={5}>
            <Typography className="f-16 fw-600 pb-1">Contact Info</Typography>
            <Typography
              className="f-16 pb-1 d-flex align-items-center pb-2"
              color="color-para d-flex"
              mt={1}
            >
              <LocationOn fontSize="small" style={{ marginRight: 8 }} /> 23
              Harbor Lane, Suite 200, Seaside, CA 90210
            </Typography>
            <Typography
              className="f-16 pb-1 d-flex align-items-center pb-2"
              color="color-para d-flex"
              mt={1}
            >
              <Phone fontSize="small" style={{ marginRight: 8 }} /> (555)
              123-4567
            </Typography>
            <Typography
              className="f-16 pb-2 d-flex align-items-center"
              color="color-para"
              mt={1}
            >
              <Email fontSize="small" style={{ marginRight: 8 }} />{" "}
              info@centerboardwellness.com
            </Typography>

            <Box>
              <Typography className="f-18 fw-600" mt={1}>
                Subscribe to our Newsletter
              </Typography>
              <Box mt={2}>
                <Box className="subscribe-container d-flex align-items-center">
                  <TextField
                    variant="standard"
                    placeholder="Enter your email"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        flex: 1,
                        padding: "10px",
                        borderRadius: "50px",
                        fontSize: "16px",
                        color: "#808080",
                      },
                    }}
                    fullWidth
                  />
                  <Button className="subscribe-button">Subscribe</Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4, backgroundColor: "#ccc" }} />

        {/* Footer Bottom */}
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="textSecondary">
            © Copyright 2025, All Rights Reserved
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Privacy Policy | Terms & Conditions
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
