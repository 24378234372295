import VideocamIcon from "@mui/icons-material/Videocam";
import { Box, Typography } from "@mui/material";
import images from "../../../assets/images";
import "../../../assets/style.css";
import CustomAppBar from "../../components/CustomAppBar";
import CourseClassesCard from "./components/CourseClassesCard";
import PodcastCard from "./components/PodcastCard";
import "./podcast.css";

const appBarData = [
  {
    title: "Podcast Learning",
    icon: <VideocamIcon />,
    xp: "400 XP",
    notifications: 7,
    profileImage: images.kimAvatar,
  },
];

const cardData = [
  {
    id: 1,
    title: "Legal Ramifications",
    episodes: 3,
    host: "Michael Jenkins",
    imgSrc: images.strm1,
  },
  {
    id: 2,
    title: "Legal Ramifications",
    episodes: 5,
    host: "Michael Jenkins",
    imgSrc: images.strm2,
  },
  {
    id: 3,
    title: "Legal Ramifications",
    episodes: 4,
    host: "Michael Jenkins",
    imgSrc: images.strm3,
  },
  {
    id: 4,
    title: "Legal Ramifications",
    episodes: 2,
    host: "Michael Jenkins",
    imgSrc: images.strm4,
  },
  {
    id: 5,
    title: "Legal Ramifications",
    episodes: 4,
    host: "Michael Jenkins",
    imgSrc: images.strm5,
  },
  {
    id: 6,
    title: "Legal Ramifications",
    episodes: 3,
    host: "Michael Jenkins",
    imgSrc: images.strm6,
  },
];

export default () => {
  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />
      <Box className="w-100">
        <CourseClassesCard
          title="DUI Classes: Overview of mandatory education."
          description="Earn 30 XP after listening to this episode."
          instructor="Michael Jenkins"
          duration="54 min"
          progress={2}
          totalLessons={24}
          xp={3300}
        />
        {/* Podcast Library */}
        <Box>
          <Typography
            py={3}
            className="sub_header text-black fw-500 font-poppins"
          >
            Podcast Library
          </Typography>
          <PodcastCard cardData={cardData} />
        </Box>
      </Box>
    </Box>
  );
};
