import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Box, Typography } from "@mui/material";
import React from "react";
import images from "../../../../assets/images";

export default function RewardAlert() {
  return (
    <Box
      className="d-flex align-items-center justify-content-end position-absolute"
      sx={{ right: 0, top: "-10px", zIndex: 1 }}
    >
      <Box
        sx={{
          p: 2,
          textAlign: "left",
          alignItems: "flex-start",
          gap: 2,
        }}
        className="d-flex align-items-start alert_card border-r-8"
      >
        <EmojiEventsIcon sx={{ color: "var(--color-darkSky)", fontSize: 25 }} />
        <Box>
          <Typography mb="5px" className="font-poppins text-black fw-500 f-16">
            Earn, Redeem, Thrive
          </Typography>
          <Typography className="font-lora color-para f-14 fw-400">
            Ahoy, explorer! Every step you take towards wellness is a milestone
            worth celebrating. Here at the Centerboard Reward Store, your
            progress unlocks exciting rewards—whether it’s self-care essentials,
            expert coaching, or exclusive content to fuel your journey.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ px: 3 }} className="alert_img">
        <img
          src={images.rewardAlert}
          alt="Quiz Robot"
          width={120}
          height={150}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Box>
    </Box>
  );
}
