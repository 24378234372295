import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";

const CustomBreadcrumbs = ({ icon: Icon, breadcrumbItems, sx, className }) => {
  return (
    <Box
      sx={{ p: 1, flexWrap: "wrap", ...sx }}
      className={`d-flex align-items-center ${className}`}
    >
      {Icon && <Icon fontSize="small" sx={{ mr: 1 }} />}

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbItems.map((item, index) =>
          item.href ? (
            <Link
              key={index}
              href={item.href}
              underline="hover"
              className="color-para font-poppins fw-500 f-16"
            >
              {item.label}
            </Link>
          ) : (
            <Typography
              key={index}
              className="text-darksky font-poppins fw-500 f-16"
            >
              {item.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default CustomBreadcrumbs;
