import AdminDashboard from "../website/AdminDasboard";
import PodcastManagement from "../website/AdminDasboard/PodcastManagement";
import TwelveWeeksPlan from "../website/Dashboard/TwelveWeeksPlan";

export const adminPrivateRoutes = [
  {
    path: "/admin",
    element: <AdminDashboard />,
    children: [
      { path: "podcast-management", element: <PodcastManagement /> },
      { path: "twelve-weeks-plans", element: <TwelveWeeksPlan /> },
      // { path: "reward-store", element: <RewardStore /> },
    ],
  },
];
