import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LoopIcon from "@mui/icons-material/Loop";
import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import images from "../../../assets/images";
import CustomAdminBar from "../../components/CustomAdminBar";
import CustomRewardCards from "../../components/CustomRewardCards";
import CustomDatePicker from "./components/CustomDatePicker";
import "./reward.css";

export default function PodcastManagement() {
  const [fromDate, setFromDate] = useState(dayjs("2022-04-01"));
  const [toDate, setToDate] = useState(dayjs("2022-04-17"));

  const appBarData = [
    {
      title: "Podcast Management",
      icon: <ImportContactsIcon />,
      profileImage: images.kimAvatar,
      description: "Manage podcasts, episodes, and engagement.",
      userName: "Diana Lin",
    },
  ];

  const data = [
    {
      title: "Available Compass Credits",
      value: 6000,
      buttonText: "Earn more compass credits",
      condition: "available",
    },
    {
      title: "Lifetime Compass Credits",
      value: 12750,
      buttonText: null,
      condition: "lifetime",
    },
    {
      title: "Redeemed",
      value: 6750,
      buttonText: null,
      condition: "redeemed",
    },
    {
      title: "Unlock Level 5",
      value: 1500,
      buttonText: null,
      condition: "unlock",
    },
  ];

  return (
    <Box className="w-100">
      <CustomAdminBar {...appBarData[0]} />
      <Box mt={4}>
        <Box
          className="d-flex justify-content-between align-items-center"
          my={3}
        >
          <Typography className="f-18 font-poppins fw-500" mb={2}>
            Podcast Engagement Metrics
          </Typography>
          <Box className="d-flex align-items-center gap-1">
            <CustomDatePicker
              fromValue={fromDate}
              toValue={toDate}
              onFromChange={(newValue) => setFromDate(newValue)}
              onToChange={(newValue) => setToDate(newValue)}
            />

            <Button
              className="bg-darksky border-r-8 f-14 text-light font-poppins fw-400 capitalize_text gap-1"
              variant="contained"
            >
              <LoopIcon sx={{ fontSize: "18px" }} /> Refresh
            </Button>
          </Box>
        </Box>
        <CustomRewardCards data={data} />
      </Box>
    </Box>
  );
}
