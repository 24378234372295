import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import { Avatar, Card, CardContent, Stack, Typography } from "@mui/material";
import images from "../../../../assets/images";

const EpisodsClassesCard = ({ title, instructor, duration }) => {
  const courseDetails = [
    { icon: <LayersOutlinedIcon fontSize="small" />, text: duration },
  ];

  return (
    <Card
      className="border-card-1 d-flex"
      sx={{
        backgroundImage: `url(${images.episods})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 3,
        p: { xs: 1, sm: 2, md: 3 },
        height: "280px",
        alignItems: "end",
      }}
    >
      <CardContent>
        <Typography
          className="text-light fw-500 font-poppins sub_header p-0"
          sx={{ mt: 1 }}
        >
          {title}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: 1, flexWrap: "wrap" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={images.kimAvatar} sx={{ width: 24, height: 24 }} />
            <Typography className="text-light">{instructor}</Typography>
          </Stack>
          {courseDetails?.map((detail, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: "var(--color-light)" }}
            >
              {detail.icon}
              <Typography className="text-light">{detail.text}</Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default EpisodsClassesCard;
