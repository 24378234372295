import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import images from "../../../../assets/images";

const CardItem = ({ id, title, episodes, host, imgSrc }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/dashboard/podcast-learning/podcast-episods/${id}`);
  };

  return (
    <Card
      sx={{
        maxWidth: { xs: "100%", sm: "48%", md: "32%" },
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "white",
        border: "1px solid var(--color-midgray)",
        padding: "12px",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          objectFit: "contain",
          objectPosition: "center",
          borderRadius: "10px 10px 0 0",
        }}
        image={imgSrc}
        title={title}
      />
      <CardContent
        sx={{
          padding: "16px 5px",
        }}
        className="text-start"
      >
        <Typography
          variant="h6"
          className="fw-500 font-poppins text-black f-20"
        >
          {title}
        </Typography>
        <Box
          className="d-flex justify-content-between align-items-center"
          sx={{ mt: 1 }}
        >
          <Typography
            variant="body2"
            className="color-para fw-500 font-poppins f-14"
          >
            {episodes} Episodes
          </Typography>
          <Typography
            variant="body2"
            className="color-para fw-500 font-poppins f-14 d-flex align-items-center"
          >
            <Avatar
              src={images.kimAvatar}
              alt="Kim Sutherland"
              sx={{ width: 25, height: 25, mr: 1 }}
            />{" "}
            {host}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const PodcastCard = ({ cardData }) => {
  if (!cardData.length) {
    console.warn("cardData is empty or undefined");
  }
  console.log(cardData, "cardData");

  return (
    <Box
      sx={{
        flexWrap: "wrap",
        gap: "16px",
      }}
      className="d-flex justify-content-center"
    >
      {console.log(cardData, "cardData")}
      {cardData?.map((data, index) => (
        <CardItem
          key={index}
          id={data.id}
          title={data.title}
          episodes={data.episodes}
          host={data.host}
          imgSrc={data.imgSrc}
        />
      ))}
    </Box>
  );
};

export default PodcastCard;
