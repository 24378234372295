import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import { Box, Typography } from "@mui/material";
import images from "../../../assets/images";
import CustomAppBar from "../../components/CustomAppBar";
import CustomRewardCards from "../../components/CustomRewardCards";
import RecentActivity from "./components/RecentActivity";
import RewardAlert from "./components/RewardAlert";
import RewardsStoreCards from "./components/RewardsStoreCards";
import "./reward.css";

export default function RewardStore() {
  const appBarData = [
    {
      title: "Reward Store",
      icon: <ImportContactsIcon />,
      xp: "6000 XP",
      notifications: 90,
      profileImage: images.kimAvatar,
    },
  ];

  const data = [
    {
      title: "Available Compass Credits",
      value: 6000,
      buttonText: "Earn more compass credits",
      condition: "available",
    },
    {
      title: "Lifetime Compass Credits",
      value: 12750,
      buttonText: null,
      condition: "lifetime",
    },
    {
      title: "Redeemed",
      value: 6750,
      buttonText: null,
      condition: "redeemed",
    },
    {
      title: "Unlock Level 5",
      value: 1500,
      buttonText: null,
      condition: "unlock",
    },
  ];
  const rewardsData = [
    {
      title: "Personalized Feedback from Instructors",
      description:
        "Unlock one-on-one virtual coaching or therapy check-ins to...",
      xp: 2500,
      img: images.stars,
    },
    {
      title: "Guided Meditations",
      description:
        "Redeem your compass credits for wellness kits, including aromatherapy...",
      xp: 4500,
      img: images.meditation,
    },
    {
      title: "Exclusive Video Lessons",
      description:
        "Gain access to exclusive self-improvement courses, meditations...",
      xp: 12500,
      img: images.meetings,
    },
    {
      title: "Bonus Reflection Journals",
      description:
        "Trade your compass credits for branded Centerboard wellness materials...",
      xp: 5500,
      img: images.journals,
    },
  ];

  const activities = [
    {
      title: "Completed Quiz",
      time: "Today, 10:30 AM",
      xp: 300,
      isNegative: false,
      typeof: "quiz",
    },
    {
      title: "Amazon Gift Card",
      time: "Yesterday, 3:45 PM",
      xp: -1000,
      isNegative: true,
      typeof: "gift",
    },
    {
      title: "Completed DUI Navigation Chart",
      time: "2 days ago",
      xp: 1000,
      isNegative: false,
      typeof: "quiz",
    },
    {
      title: "Made an entry in Journal",
      time: "3 days ago",
      xp: 100,
      isNegative: false,
      typeof: "gift",
    },
    {
      title: "Streamed Podcast",
      time: "1 week ago",
      xp: 1000,
      isNegative: false,
      typeof: "quiz",
    },
  ];

  return (
    <Box className="w-100">
      <CustomAppBar {...appBarData[0]} />
      <Box mt={3}>
        <CustomRewardCards data={data} />
        {/*  Rewards Store */}
        <Typography className="sub_header font-poppins fw-500" my={3}>
          Rewards Store
        </Typography>
        <RewardsStoreCards rewardsData={rewardsData} />
        {/* Recent Activity */}
        <Typography className="sub_header font-poppins fw-500" my={3}>
          Recent Activity
        </Typography>
        <RecentActivity activities={activities} />
      </Box>
      <Box className="position-relative">
        <RewardAlert />
      </Box>
    </Box>
  );
}
