import { AccessTime, Star } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ReactPlayer from "react-player";
import images from "../../../../../assets/images";
import CustomAppBar from "../../../../components/CustomAppBar";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs";
import UserProfile from "../../../pages/EducationLevel/components/UserProfile";

export default function PodcastLearningEducation() {
  const appBarData = [
    {
      title: "Podcast Learning",
      icon: <VideocamIcon />,
      xp: "400 XP",
      notifications: 7,
      profileImage: images.kimAvatar,
    },
  ];

  const breadcrumbItems = [
    { label: "Podcast Learning", href: "/dashboard/podcast-learning" },
    { label: "Behavioral Education", href: "#" },
    { label: "Episode 3" },
  ];

  const podcasts = [
    {
      url: "https://videos.pexels.com/video-files/18525257/18525257-hd_1920_1080_25fps.mp4",
      title: "Introduction to Behavioral Education",
      duration: "10:30",
      speaker: "Michael Jenkins",
      thumbnail: images.podcastThumbnail1,
    },
    {
      url: "https://videos.pexels.com/video-files/11345444/11345444-uhd_2560_1440_30fps.mp4",
      title: "Understanding DUI Laws and Penalties",
      duration: "15:45",
      speaker: "Alan Walker",
      thumbnail: images.podcastThumbnail2,
    },
    {
      url: "https://videos.pexels.com/video-files/10261632/10261632-hd_1920_1080_30fps.mp4",
      title: "The Science of Impaired Driving",
      duration: "12:20",
      speaker: "Sarah Connor",
      thumbnail: images.podcastThumbnail3,
    },
  ];

  const [selectedPodcast, setSelectedPodcast] = useState(podcasts[0]);
  const [isEpisodeStructureVisible, setIsEpisodeStructureVisible] =
    useState(false);

  const VideoPlayer = ({ video }) => {
    return (
      <Box className="radius-16">
        <div className="video-wrapper">
          <ReactPlayer
            key={video.url}
            url={video.url}
            controls
            playing={true}
            width="100%"
            height="100%"
            className="radius-16"
          />
        </div>
        <Typography variant="h6" mt={2}>
          {video.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Duration: {video.duration}
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={1}>
          Speaker: {video.speaker}
        </Typography>
      </Box>
    );
  };
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: "54 min" },
    { icon: <Star fontSize="small" />, text: "30 XP" },
  ];

  return (
    <Box className="w-100">
      <CustomAppBar {...appBarData[0]} />
      <CustomBreadcrumbs
        icon={VideocamIcon}
        breadcrumbItems={breadcrumbItems}
        className="custom-class"
      />
      <Typography
        className="sub_header fw-500 font-poppins text-black"
        gutterBottom
      >
        DUI Classes: Overview of mandatory education.{" "}
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        mb={3}
        sx={{ mt: 2, flexWrap: "wrap" }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar src={images.kimAvatar} sx={{ width: 24, height: 24 }} />
          <Typography className="color-para">Michael Jenkins</Typography>
        </Stack>
        {courseDetails.map((detail, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ color: "var(--color-darkSky)" }}
          >
            {detail.icon}
            <Typography className="color-para">{detail.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <Grid container spacing={2}>
        {/* Podcast List */}

        {/* Video Player */}
        <Grid item xs={12} md={8}>
          <VideoPlayer video={selectedPodcast} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box pb={2}>
            <UserProfile
              name="Guest Speaker"
              role="Professional DUI Therapist"
              avatarSrc={images.kimAvatar}
            />
          </Box>
          <List>
            <Typography
              className="cursor-pointer f-18 fw-500 text-black font-poppins d-flex align-items-center gap-1"
              px={2}
              my={2}
              onClick={() =>
                setIsEpisodeStructureVisible(!isEpisodeStructureVisible)
              }
            >
              Episode Structure{" "}
              <KeyboardArrowDownIcon sx={{ color: "var()" }} />
            </Typography>
            {isEpisodeStructureVisible && (
              <>
                {podcasts.map((podcast, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => setSelectedPodcast(podcast)}
                    selected={selectedPodcast.title === podcast.title}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          className="f-14 fw-500 text-black font-poppins"
                        >
                          <span
                            className="f-14 fw-500 text-darkblue font-poppins"
                            style={{ marginRight: "8px" }}
                          >
                            {podcast.duration}
                          </span>
                          {podcast.title}
                        </Typography>
                      }
                      className="m-0"
                    />
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
