import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import QuizIcon from "@mui/icons-material/Quiz";
import { Box, Grid, Typography } from "@mui/material";

const RecentActivity = ({ activities }) => {
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {activities.map((activity, index) => (
            <Box
              key={index}
              sx={{
                padding: "8px 20px",
                borderBottom: "1px solid #f0f0f0",
              }}
              className="d-flex justify-content-between align-items-center"
            >
              <Box className="d-flex align-items-center gap-1">
                <Box>
                  {activity.typeof === "quiz" ? (
                    <QuizIcon
                      sx={{
                        color: "var(--color-light)",
                        background: "var(--color-darkSky)",
                        width: "30px",
                        height: "30px",
                      }}
                      className="quiz_icon"
                    />
                  ) : (
                    <CardGiftcardIcon
                      sx={{
                        color: "var(--color-light)",
                        background: "var(--color-darkSky)",
                        width: "30px",
                        height: "30px",
                      }}
                      className="quiz_icon"
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    className={`f-16 font-poppins fw-500 ${
                      activity.isNegative ? "text-gray" : "text-black"
                    }`}
                  >
                    {activity.title}
                  </Typography>

                  <Typography className="color-para f-14 font-lora fw-400">
                    {activity.time}
                  </Typography>
                </Box>
              </Box>

              <Typography
                className={`f-16 font-poppins fw-500 ${
                  activity.isNegative ? "text-black" : "text-darksky"
                }`}
              >
                {activity.isNegative
                  ? `- ${Math.abs(activity.xp)} XP`
                  : `+ ${activity.xp} XP`}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecentActivity;
