import BoltIcon from "@mui/icons-material/Bolt";
import HomeIcon from "@mui/icons-material/Home";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const CustomAppBar = ({
  title = "Home",
  icon = <HomeIcon />,
  xp = "10 XP",
  notifications = 1,
  profileImage,
  onEditProfile,
  onDeleteProfile,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          mb: "20px",
          gap: "20px",
        }}
        className="px-0"
      >
        <Box
          className="d-flex align-items-center justify-content-between"
          gap={1}
        >
          <IconButton
            className="icons_border text-darkblue"
            sx={{ p: "3px", borderRadius: "5px" }}
          >
            {icon}
          </IconButton>
          <Typography className="text-darkblue fw-500 sub_header font-poppins">
            {title}
          </Typography>
        </Box>

        {/* Right Section - Icons and Profile */}
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <BoltIcon className="text-darksky" />
            <Typography className="f-14 color-para">{notifications}</Typography>
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <MonetizationOnIcon sx={{ color: "#FFD700" }} />
            <Typography sx={{ fontSize: 14, color: "#333" }}>{xp}</Typography>
          </Box>

          <IconButton>
            <Badge
              color="error"
              variant="dot"
              className="icons_border"
              sx={{ p: "3px", borderRadius: "5px" }}
            >
              <NotificationsNoneIcon className="color-para" />
            </Badge>
          </IconButton>

          <IconButton onClick={handleClick}>
            <Avatar src={profileImage} alt="Profile" />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                if (onEditProfile) onEditProfile();
              }}
            >
              Edit Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                if (onDeleteProfile) onDeleteProfile();
              }}
            >
              Delete Profile
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
