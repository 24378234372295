import { Box, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import PodcastEpisodesCard from "./PodcastEpisodesCard";

export default function EpisodsTabs() {
  const [tabIndex, setTabIndex] = useState(0);

  const episodes = [
    {
      id: 1,
      name: "Episode 1",
      title: "Introduction: Overview of podcast purpose and themes.",
      host: "Michael Jenkins",
      guest: "Karla Winselt",
      xp: 50,
      time: "30:00",
      videoUrl:
        "https://videos.pexels.com/video-files/4329192/4329192-hd_1920_1080_30fps.mp4",
    },
    {
      id: 2,
      name: "Episode 2",
      title: "Police: Interaction with law enforcement after a DUI.",
      host: "John CIna",
      guest: "Karla John",
      xp: 40,
      time: "25:00",
      videoUrl:
        "https://videos.pexels.com/video-files/7218292/7218292-uhd_2560_1440_25fps.mp4",
    },
  ];

  return (
    <div>
      <Grid item xs={12} md={12}>
        <Box className="tabs_one_hero">
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => setTabIndex(newValue)}
            sx={{
              mt: 2,
              "& .MuiTabs-indicator": {
                backgroundColor: "var(--color-darkSky)",
              },
              "& .MuiTab-root": {
                color: "var(--color-dark)",
              },
              "& .Mui-selected": {
                color: "#00acc1",
                fontWeight: "bold",
              },
            }}
          >
            <Tab label="Episodes" />
            <Tab label="Resources" />
          </Tabs>
        </Box>
        {tabIndex === 0 && (
          <Box mt={2}>
            <PodcastEpisodesCard episodeTab={true} episodes={episodes} />
          </Box>
        )}
        {tabIndex === 1 && (
          <Box variant="body1" mt={2}>
            <Box mt={2}>
              <PodcastEpisodesCard resource={true} episodes={episodes} />
            </Box>
          </Box>
        )}
      </Grid>
    </div>
  );
}
