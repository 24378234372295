import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

export default function CustomDatePicker({
  fromValue,
  toValue,
  onFromChange,
  onToChange,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="d-flex gap-2">
        <DatePicker label="From" value={fromValue} onChange={onFromChange} />
        <DatePicker label="To" value={toValue} onChange={onToChange} />
      </Box>
    </LocalizationProvider>
  );
}
