import { Box } from "@mui/material";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import "./assets/style.css";
import PrivateRoute from "./PrivateRoute";
import { adminPrivateRoutes } from "./routes/AdminPrivateRoutes";
import { curriculumRoutes } from "./routes/CurriculumRoutes";
import { privateRoutes } from "./routes/PrivateRoutes";
import { publicRoutes } from "./routes/PublicRoutes";
import Footer from "./website/Footer";
import NavbarApp from "./website/NavbarApp";

const AnimatedPageWrapper = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <div>{children}</div>;
};

const isPrivateRoute =
  location.pathname.startsWith("/dashboard") ||
  location.pathname.startsWith("/curriculum") ||
  location.pathname.startsWith("/admin");

function App() {
  return (
    <Router>
      {!isPrivateRoute && <NavbarApp />}
      <AnimatedPageWrapper>
        <Box
          component="main"
          sx={{
            py: 3,
            width: "100%",
            mt: "42px",
            background: "var(--color-light)",
          }}
        >
          <Routes>
            {/* Public Routes */}
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* Private Routes */}
            <Route element={<PrivateRoute />}>
              {privateRoutes.map((route, index) => (
                <Route
                  key={`private-${index}`}
                  path={route.path}
                  element={route.element}
                >
                  {route.children?.map((childRoute, childIndex) => (
                    <Route
                      key={`private-child-${childIndex}`}
                      path={childRoute.path}
                      element={childRoute.element}
                    />
                  ))}
                </Route>
              ))}

              {/* Curriculum Routes */}
              {curriculumRoutes.map((route, index) => (
                <Route
                  key={`curriculum-${index}`}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Route>

            {/* Admin Private Routes */}
            <Route element={<PrivateRoute />}>
              {adminPrivateRoutes.map((route, index) => (
                <Route
                  key={`admin-private-${index}`}
                  path={route.path}
                  element={route.element}
                >
                  {route.children?.map((childRoute, childIndex) => (
                    <Route
                      key={`admin-private-child-${childIndex}`}
                      path={childRoute.path}
                      element={childRoute.element}
                    />
                  ))}
                </Route>
              ))}
            </Route>
          </Routes>
        </Box>
      </AnimatedPageWrapper>
      {!isPrivateRoute && <Footer />}
    </Router>
  );
}

export default App;
