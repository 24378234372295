import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
  const token = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("authToken");
  console.log("Token in PrivateRoute:", token);
  console.log("Token in PrivateRoute:", userRole);

  if (!token) {
    return <Navigate to="/login-account" />;
  }
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};

export default PrivateRoute;
