import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const CustomAdminBar = ({ title, profileImage, description, userName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "transparent", boxShadow: "none" }}
    >
      <Box className="d-flex justify-content-between align-items-center w-100">
        <Box className="d-flex flex-column" gap={1}>
          {/* <IconButton
            className="icons_border text-darkblue"
            sx={{ p: "3px", borderRadius: "5px" }}
          >
            {icon}
          </IconButton> */}
          <Typography className="text-darkblue fw-500 sub_header font-poppins">
            {title}
          </Typography>
          <Typography className="color-para fw-500 f-14 font-poppins">
            {description}
          </Typography>
        </Box>

        {/* Right Section - Icons and Profile */}
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton className="d-flex gap-1">
            <Avatar onClick={handleClick} src={profileImage} alt="Profile" />
            <Box className="text-start">
              <Typography className="f-16 text-black font-poppins fw-500">
                {userName}
              </Typography>
              <Typography className="color-para fw-400 f-12 font-poppins">
                Admin User
              </Typography>
            </Box>
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                if (onEditProfile) onEditProfile();
              }}
            >
              Edit Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                if (onDeleteProfile) onDeleteProfile();
              }}
            >
              Delete Profile
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
};

export default CustomAdminBar;
