import { AccessTime } from "@mui/icons-material";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../../../assets/images";

const CourseClassesCard = ({
  id,
  title,
  description,
  instructor,
  duration,
  progress,
  totalLessons,
}) => {
  const courseDetails = [
    { icon: <AccessTime fontSize="small" />, text: duration },
  ];
  const navigate = useNavigate();

  return (
    <Card
      className="border-card-1"
      sx={{
        backgroundImage: `url(${images.streaming})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 3,
        p: { xs: 1, sm: 2, md: 3 },
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography className="icons_color fw-500 font-poppins f-14 d-flex align-items-center gap-1 mb-1">
              <WifiTetheringIcon sx={{ color: "var(--color-darkyellow)" }} />{" "}
              Streaming Now
            </Typography>
            <Typography
              className="text-light fw-500 font-poppins sub_header"
              sx={{ mt: 1 }}
            >
              {title}
            </Typography>
          </Box>
        </Stack>

        {/* Description */}
        <Typography
          className="text-light fw-500 font-poppins f-14"
          sx={{ mt: 1 }}
        >
          {description}
        </Typography>

        {/* Progress Bar */}
        <Box sx={{ mt: 2 }}>
          <LinearProgress
            variant="determinate"
            value={(progress / totalLessons) * 100}
            sx={{
              my: 2,
              p: "6px",
              borderRadius: "8px",
              backgroundColor: "lightgray",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#00ACC1",
              },
            }}
          />
          <Typography className="text-light fw-500 font-poppins f-14">
            {progress} of {totalLessons} classes completed
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ mt: 3, flexWrap: "wrap" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={images.kimAvatar} sx={{ width: 24, height: 24 }} />
            <Typography className="text-light">{instructor}</Typography>
          </Stack>
          {courseDetails.map((detail, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ color: "var(--color-light)" }}
            >
              {detail.icon}
              <Typography className="text-light">{detail.text}</Typography>
            </Stack>
          ))}
        </Stack>

        {/* Buttons and XP */}
        <Box mt={3}>
          <Button
            variant="contained"
            className="contact_btn f-16 m-0"
            sx={{ mt: 1, px: "20px" }}
            component={Link}
            to="podcast-learning-overview"
          >
            Join Now{" "}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CourseClassesCard;
