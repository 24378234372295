import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

const RewardsStoreCards = ({ rewardsData }) => {
  return (
    <Grid container spacing={3} justifyContent="start" className="w-100">
      {rewardsData.map((reward, index) => (
        <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
          <Card sx={{ height: "100%" }}>
            <CardActionArea>
              <Box>
                <CardMedia
                  component="img"
                  height="170"
                  image={reward.img}
                  alt={reward.title}
                  width="100%"
                />
              </Box>
              <CardContent>
                <Typography
                  className="text-black f-16 font-poppins fw-500"
                  gutterBottom
                >
                  {reward.title}
                </Typography>
                <Typography
                  className="color-para f-14 font-lora fw-400"
                  paragraph
                >
                  {reward.description}
                </Typography>
                <Box
                  className="d-flex justify-content-between align-items-center"
                  mt={2}
                >
                  <Typography className="text-black f-16 font-poppins fw-500">
                    {reward.xp} XP
                  </Typography>
                  <Button
                    className="bg-darksky border-r-8 f-14 text-light font-poppins fw-400 capitalize_text"
                    variant="contained"
                  >
                    Redeem
                  </Button>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default RewardsStoreCards;
