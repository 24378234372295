import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

const UserProfile = ({ name, role, avatarSrc }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mt={2}
      sx={{ flexWrap: "wrap" }}
    >
      <Box display="flex" alignItems="center">
        <Avatar
          src={avatarSrc}
          alt={name}
          sx={{ width: 40, height: 40, mr: 1.5 }}
        />
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {role}
          </Typography>
        </Box>
      </Box>

      <Typography
        className="font-poppins text-blue fw-500 f-16 d-flex align-items-center gap-1"
        sx={{ cursor: "pointer" }}
      >
        View Transcript <KeyboardArrowDownIcon />
      </Typography>
    </Box>
  );
};

export default UserProfile;
