import ceo from "./aboutus/ceo.png";
import aboutusBanner from "./aboutus/crew.png";
import director from "./aboutus/director.png";
import kimAvatar from "./aboutus/kim.png";
import manager from "./aboutus/manager.png";
import mission from "./aboutus/mission.png";
import spnsr from "./aboutus/spnsr.png";
import sponserBanner from "./aboutus/sponser.png";
import winds from "./aboutus/winds.png";
import anchorBanner from "./anchor/anchor.png";
import education from "./anchor/education.png";
import pdOne from "./anchor/pd1.png";
import pdTwo from "./anchor/pd2.png";
import pdThree from "./anchor/pd3.png";
import program from "./anchor/programms.png";
import research from "./anchor/research.png";
import speaker from "./anchor/speaker.png";
import captionBanner from "./captains/captain-banner.png";
import compas from "./captains/compas.png";
import guider from "./captains/guider.png";
import logBook from "./captains/personal-log.png";
import cdOne from "./curriculum/cd1.png";
import cdTwo from "./curriculum/cd2.png";
import cdthree from "./curriculum/cd3.png";
import cdFour from "./curriculum/cd4.png";
import cdFive from "./curriculum/cd5.png";
import compass from "./curriculum/compass.png";
import cbanner from "./curriculum/course-banner.png";
import cr1 from "./curriculum/cr1.png";
import cr2 from "./curriculum/cr2.png";
import cr3 from "./curriculum/cr3.png";
import cr4 from "./curriculum/cr4.png";
import cr5 from "./curriculum/cr5.png";
import cr6 from "./curriculum/cr6.png";
import degree from "./curriculum/degree.png";
import cdBanner from "./curriculum/detail-banner.png";
import discoveryWeek from "./curriculum/discovery-week.png";
import discovery from "./curriculum/discovery.png";
import empower from "./curriculum/empower.png";
import empowerment from "./curriculum/empowerment.png";
import file from "./curriculum/file.png";
import focus from "./curriculum/focus.png";
import lifeOne from "./curriculum/focus1.png";
import lifeTwo from "./curriculum/focus2.png";
import lifeThree from "./curriculum/focus3.png";
import lifeFour from "./curriculum/focus4.png";
import lifeFive from "./curriculum/focus5.png";
import lifeSix from "./curriculum/focus6.png";
import lifeSeven from "./curriculum/focus7.png";
import growth from "./curriculum/growth.png";
import leadOne from "./curriculum/lead1.png";
import leadTwo from "./curriculum/lead2.png";
import leadThree from "./curriculum/lead3.png";
import leadBanner from "./curriculum/leader-banner.png";
import legalBanner from "./curriculum/legal-banner.png";
import legalHandbook from "./curriculum/legal.png";
import matters from "./curriculum/matters.png";
import reflection from "./curriculum/reflection.png";
import reviewTwo from "./curriculum/review2.png";
import reviewThree from "./curriculum/review3.png";
import reviewOne from "./curriculum/sara.png";
import seaBanner from "./curriculum/sea-banner.png";
import star from "./curriculum/start.png";
import thumb from "./curriculum/thumb.png";
import treasureBanner from "./curriculum/treasure-banner.png";
import whyworks from "./curriculum/whyworks.png";
import women from "./curriculum/women.png";
import blueBadge from "./dashboard/blue.png";
import corrected from "./dashboard/corrected.png";
import darkBadge from "./dashboard/dark.png";
import episods from "./dashboard/episods.png";
import fish from "./dashboard/fish.png";
import gold from "./dashboard/gold.png";
import micIcon from "./dashboard/mic.png";
import purple from "./dashboard/purple.png";
import quizRobot from "./dashboard/quiz.png";
import rankIcon from "./dashboard/rank.png";
import robot from "./dashboard/robot.svg";
import silver from "./dashboard/silver.png";
import smallRobot from "./dashboard/small-robot.png";
import startQuiz from "./dashboard/startQuiz.png";
import streaming from "./dashboard/streaming.png";
import strm1 from "./dashboard/strm1.png";
import strm2 from "./dashboard/strm2.png";
import strm3 from "./dashboard/strm3.png";
import strm4 from "./dashboard/strm4.png";
import strm5 from "./dashboard/strm5.png";
import strm6 from "./dashboard/strm6.png";
import journals from "./dashboard/journals.png";
import meditation from "./dashboard/meditation.png";
import meetings from "./dashboard/meetings.png";
import stars from "./dashboard/star.png";
import rewardAlert from "./dashboard/rewardAlert.png";
import workRobot from "./dashboard/work-robot.png";
import wrongQuiz from "./dashboard/wrong-quiz.png";
import disability from "./hoilistic/disability.png";
import dui from "./hoilistic/dui.png";
import holistic from "./hoilistic/holistic.png";
import info from "./hoilistic/info.png";
import information from "./hoilistic/information.png";
import wellness from "./hoilistic/wellness.png";
import wheel from "./hoilistic/wheel.png";
import chartBanner from "./home/chart-banner.png";
import chart1 from "./home/chart1.png";
import chart2 from "./home/chart2.png";
import chart3 from "./home/chart3.png";
import chart4 from "./home/chart4.png";
import chart5 from "./home/chart5.png";
import comma1 from "./home/comma1.png";
import comma2 from "./home/comma2.png";
import discover1 from "./home/discover1.png";
import discover2 from "./home/discover2.png";
import discover3 from "./home/discover3.png";
import embBanner from "./home/emb-banner.png";
import emb1 from "./home/emb1.png";
import emb2 from "./home/emb2.png";
import emb3 from "./home/emb3.png";
import founder from "./home/founder.png";
import banner from "./home/holistic.png";
import journey from "./home/journey.png";
import map from "./home/map.png";
import oc1 from "./home/oc1.png";
import oc2 from "./home/oc2.png";
import oc3 from "./home/oc3.png";
import oceanBanner from "./home/ocean-banner.png";
import one from "./home/one.png";
import three from "./home/three.png";
import two from "./home/two.png";
import confirmed from "./images/confirmed.png";
import contact from "./images/contact.png";
import dLogo from "./images/d-logo.png";
import errorBanner from "./images/error.png";
import flogo from "./images/flogo.png";
import google from "./images/google.png";
import logo from "./images/logo.png";
import wellcome from "./images/well-back.png";
import steps from "./treasure/steps.png";
import treasureChestBanner from "./treasure/treasure-banner.png";

const images = {
  logoIcon: logo,
  bannerImg: banner,
  oneIcon: one,
  twoIcon: two,
  threeIcon: three,
  chartOne: chart1,
  chartTwo: chart2,
  chartThree: chart3,
  chartFour: chart4,
  chartFive: chart5,
  chartBanner,
  embOne: emb1,
  embTwo: emb2,
  embthree: emb3,
  embB: embBanner,
  discoverOne: discover1,
  discoverTwo: discover2,
  discoverThree: discover3,
  oceanBanner,
  commaOne: comma1,
  commaTwo: comma2,
  ocOne: oc1,
  ocTwo: oc2,
  ocThree: oc3,
  founder,
  map,
  journey,
  flogo,
  cbanner,
  crOne: cr1,
  crTwo: cr2,
  crThree: cr3,
  crFour: cr4,
  crFive: cr5,
  crSix: cr6,
  cdBanner,
  women,
  reviewOne,
  growth,
  cdOne,
  cdTwo,
  cdthree,
  cdFour,
  cdFive,
  degree,
  star,
  file,
  thumb,
  reviewThree,
  reviewTwo,
  discoveryWeek,
  seaBanner,
  treasureBanner,
  reflection,
  discovery,
  empowerment,
  leadBanner,
  leadOne,
  leadTwo,
  leadThree,
  lifeOne,
  lifeTwo,
  lifeThree,
  lifeFour,
  lifeFive,
  lifeSix,
  lifeSeven,
  whyworks,
  focus,
  compass,
  legalBanner,
  matters,
  empower,
  legalHandbook,
  holistic,
  disability,
  wellness,
  information,
  info,
  dui,
  speaker,
  program,
  pdThree,
  pdTwo,
  pdOne,
  education,
  anchorBanner,
  research,
  aboutusBanner,
  winds,
  kimAvatar,
  mission,
  director,
  manager,
  sponserBanner,
  ceo,
  spnsr,
  wellcome,
  errorBanner,
  wheel,
  google,
  logBook,
  captionBanner,
  guider,
  compas,
  treasureChestBanner,
  steps,
  contact,
  dLogo,
  robot,
  rankIcon,
  smallRobot,
  fish,
  workRobot,
  darkBadge,
  silver,
  purple,
  blueBadge,
  gold,
  quizRobot,
  startQuiz,
  micIcon,
  wrongQuiz,
  corrected,
  confirmed,
  wheel,
  streaming,
  strm1,
  strm2,
  strm3,
  strm4,
  strm5,
  strm6,
  episods,
  rewardAlert,
  meditation,
  journals,
  meetings,
  stars
};

export default images;
