import { Route } from "react-router-dom";
import {
  features,
  skilledData,
  skilledDetailData,
} from "../assets/data/aboutus";
import {
  compassData,
  guiderData,
  logBookData,
  worksDetailData,
} from "../assets/data/caption";
import {
  streetData,
  streetDetailData,
  categoriesData,
  categoriesDetailData,
  engagementData,
} from "../assets/data/treasure";
import { pricingPlans } from "../assets/data/pricing";
import {
  poweredData,
  poweredDetailData,
} from "../assets/data/sponser";
import { holisticCardsData } from "../assets/data/curriculum";

import AboutUs from "../website/AboutUs";
import Sponsors from "../website/AboutUs/Sponsors";
import Captains from "../website/Captains";
import ContactUs from "../website/ContactUs";
import CreateAccount from "../website/CreateAccount";
import ExplorerGuide from "../website/ExplorerGuide";
import Home from "../website/Home";
import Login from "../website/Login";
import LoginAccount from "../website/LoginAccount";
import NotFound from "../website/NotFound";
import OTPVerification from "../website/OTPVerification";
import PasswordChanged from "../website/PasswordChanged";
import PricingPlans from "../website/PricingPlans";
import ResetPassword from "../website/ResetPassword";
import Signup from "../website/Signup";
import TreasureChest from "../website/TreasureChest";
import ForgotPassword from "../website/ForgotPassword";
import { podcastData } from "../assets/data/anchorPodcast";

export const publicRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/create-account",
    element: <CreateAccount />,
  },
  {
    path: "/login-account",
    element: <LoginAccount />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/otp-verification",
    element: <OTPVerification />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/password-changed",
    element: <PasswordChanged />,
  },
  {
    path: "/about",
    element: (
      <AboutUs
        podcastData={podcastData}
        skilledData={skilledData}
        skilledDetailData={skilledDetailData}
        features={features}
      />
    ),
  },
  {
    path: "/sponsers",
    element: (
      <Sponsors
        poweredData={poweredData}
        poweredDetailData={poweredDetailData}
      />
    ),
  },
  {
    path: "/captains-quarters",
    element: (
      <Captains
        worksDetailData={worksDetailData}
        guiderData={guiderData}
        logBookData={logBookData}
        compassData={compassData}
      />
    ),
  },
  {
    path: "/treasure-chest",
    element: (
      <TreasureChest
        streetData={streetData}
        streetDetailData={streetDetailData}
        categoriesData={categoriesData}
        categoriesDetailData={categoriesDetailData}
        engagementData={engagementData}
      />
    ),
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/pricing-plans",
    element: <PricingPlans pricingPlans={pricingPlans} />,
  },
  {
    path: "/explorer-guide",
    element: <ExplorerGuide holisticCardsData={holisticCardsData} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];