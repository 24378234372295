import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import images from "../../../../../assets/images";

const PodcastEpisodesCard = ({
  episodes,
  onPlay,
  onDownload,
  episodeTab,
  resource,
}) => {
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handlePlay = (id, videoUrl) => {
    setCurrentVideo(videoUrl);
    setOpen(true);
    onPlay(id);
  };

  const handleDownload = (id) => {
    onDownload(id);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentVideo(null);
  };

  return (
    <Box sx={{ margin: "20px 0" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="font-poppins f-14 fw-500">#</TableCell>
              <TableCell className="font-poppins f-14 fw-500">
                {episodeTab ? "Episode Name" : "Download Audio Episodes"}
              </TableCell>
              {episodeTab && (
                <TableCell className="font-poppins f-14 fw-500">
                  XP Earned
                </TableCell>
              )}

              {episodeTab && (
                <TableCell className="font-poppins f-14 fw-500">Time</TableCell>
              )}
              <TableCell
                className="font-poppins f-14 fw-500 all_center"
                sx={{ display: "flex" }}
              >
                {!episodeTab ? (
                  <span
                    style={{
                      border: "1px solid var(--color-darkSky)",
                      color: "var(--color-darkSky)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    Downloads All
                  </span>
                ) : (
                  "Actions"
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {episodes.map((episode, index) => (
              <TableRow key={episode.id}>
                <TableCell>
                  {!episodeTab ? (
                    <>
                      <Typography className="font-poppins f-16 fw-500">
                        {episode.name}
                      </Typography>
                      <Typography className="font-poppins f-14 fw-500">
                        {episode.description}
                      </Typography>
                    </>
                  ) : (
                    <Box className="d-flex align-items-center gap-1">
                      <IconButton
                        sx={{ color: "gray" }}
                        onClick={() => handlePlay(episode.id, episode.videoUrl)}
                      >
                        <PlayArrowIcon />
                      </IconButton>

                      <Typography
                        variant="h6"
                        className="font-poppins f-16 fw-500"
                      >
                        {episode.name}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    className="font-poppins f-16 fw-500"
                    mb={1}
                  >
                    {episode.title}
                  </Typography>
                  <Box className="d-flex align-items-center gap-1" gap={3}>
                    <Typography variant="body2">
                      {episodeTab && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            src={images.kimAvatar}
                            sx={{ width: 24, height: 24 }}
                          />
                          <Typography
                            variant="body2"
                            className="font-poppins f-14"
                          >
                            {episode.host}
                          </Typography>
                        </Stack>
                      )}
                    </Typography>

                    <Typography
                      variant="body2"
                      className="fw-500 f-14 font-poppins"
                    >
                      {episodeTab && episode.guest && (
                        <>
                          Guest Speaker:{" "}
                          <span className="fw-400">{episode.guest}</span>
                        </>
                      )}
                    </Typography>
                  </Box>
                </TableCell>
                {episodeTab && (
                  <TableCell className="font-poppins f-16 fw-500">
                    {episode.xp}
                  </TableCell>
                )}
                {episodeTab && (
                  <TableCell className="font-poppins f-16 fw-500">
                    {episode.time}
                  </TableCell>
                )}{" "}
                <TableCell>
                  <Box className="d-flex gap-1 align-items-center justify-content-center">
                    {episodeTab && (
                      <Button
                        variant="contained"
                        className="fw-400 d-flex gap-1 zoom_btn f-14"
                      >
                        Join with Zoom
                        <OpenInNewOutlinedIcon sx={{ fontSize: "20px" }} />
                      </Button>
                    )}
                    <DownloadForOfflineOutlinedIcon
                      onClick={() => handleDownload(episode.id)}
                      className="cursor-pointer"
                      sx={{ color: "var(--color-black)", fontSize: "30px" }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        className="all_center"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--color-yellow)",
            padding: "10px",
            borderRadius: "8px",
            outline: "none",
            width: "100%",
            maxWidth: "900px",
            opacity: 0.9,
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "#fff",
              zIndex: 1000,
            }}
          >
            <CloseIcon />
          </IconButton>

          {currentVideo ? (
            <video
              controls
              autoPlay
              style={{
                width: "100%",
                height: "auto",
              }}
            >
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Typography variant="h6">No video available</Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default PodcastEpisodesCard;
