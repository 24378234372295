import Dashboard from "../website/Dashboard";
import TwelveWeeksPlan from "../website/Dashboard/TwelveWeeksPlan";
import RewardStore from "../website/Dashboard/RewardStore";
import PodcastLearning from "../website/Dashboard/PodcastLearning";
import PodcastLearningEducation from "../website/Dashboard/PodcastLearning/pages/components/PodcastLearningEducation";
import PodcastEpisods from "../website/Dashboard/PodcastLearning/pages/PodcastEpisods";
import HippaCompliance from "../website/Dashboard/HippaCompliance";
import HippaEditForm from "../website/Dashboard/HippaCompliance/HippaEditForm";
import ProfileInformation from "../website/Dashboard/HippaCompliance/ProfileInformation";
import DashboardCourses from "../website/Dashboard/pages/DashboardCources";
import DashboardHome from "../website/Dashboard/pages/DashboardHome";
import EducationLevel from "../website/Dashboard/pages/EducationLevel";
import EndQuiz from "../website/Dashboard/pages/EndQuiz";
import EnrolledCourses from "../website/Dashboard/pages/EnrolledCourses";
import HowWorks from "../website/Dashboard/pages/HowWorks";
import FinalQuiz from "../website/Dashboard/FinalQuiz";
import QuizGame from "../website/Dashboard/QuizGame";

export const privateRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { path: "home", element: <DashboardHome /> },
      { path: "twelve-weeks-plans", element: <TwelveWeeksPlan /> },
      { path: "reward-store", element: <RewardStore /> },
      { path: "podcast-learning", element: <PodcastLearning /> },
      {
        path: "podcast-learning/podcast-learning-overview",
        element: <PodcastLearningEducation />,
      },
      {
        path: "podcast-learning/podcast-episods/:id",
        element: <PodcastEpisods />,
      },
      { path: "hippa-compliance", element: <HippaCompliance /> },
      {
        path: "hippa-compliance/edit-hippa-compliance",
        element: <HippaEditForm />,
      },
      {
        path: "profile-information",
        element: <ProfileInformation />,
      },
      { path: "courses", element: <DashboardCourses /> },
      { path: "how-it-works", element: <HowWorks /> },
      { path: "enrolled-courses", element: <EnrolledCourses /> },
      {
        path: "enrolled-courses/education-level",
        element: <EducationLevel />,
      },
      {
        path: "enrolled-courses/final-quiz",
        element: <FinalQuiz />,
      },
      {
        path: "enrolled-courses/quiz-game",
        element: <QuizGame />,
      },
      {
        path: "enrolled-courses/end-quiz",
        element: <EndQuiz />,
      },
    ],
  },
];