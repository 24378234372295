import {
  growthData,
  pageData,
  moduleData,
  accordionData,
  reviewsData,
  weekAccordianData,
  weekModule,
  trasuresData,
  gemsModule,
  infoCardData,
  leadershipModule,
  leadershipData,
  lifeData,
  whyItWorks,
  strengthData,
  personalData,
  legalData,
} from "../assets/data/curriculum";
import {
  legalHandbookData,
  whyItMatetrs,
  confidenceData,
  handbookaccordionData,
  reviewhandlebookDAta,
  highlightsAccordianData,
  highlightsData,
} from "../assets/data/legalbook";
import {
  holisticBannerData,
  duiJourneyData,
  programsData,
  programsAccordianData,
  drivesData,
  classDetails,
  drivesChangeData,
} from "../assets/data/holisticJourney";
import {
  educationPodcast,
  faceFearData,
  faceFearDetail,
  surveyData,
  sampleQuestions,
  podcastStructure,
  podcastStructureData,
  podcastData,
} from "../assets/data/anchorPodcast";

import SeaDiscovery from "../website/ExplorerGuide/ExplorerGuideDetail/SeaDiscovery";
import TwelveWeek from "../website/ExplorerGuide/ExplorerGuideDetail/TwelveWeek";
import DailyTreasure from "../website/ExplorerGuide/ExplorerGuideDetail/components/DailyTreasure";
import LegalKnowledges from "../website/ExplorerGuide/ExplorerGuideDetail/LegalKnowledge";
import HolisticJourney from "../website/ExplorerGuide/ExplorerGuideDetail/HolisticJourney";
import EducationalPodcast from "../website/ExplorerGuide/ExplorerGuideDetail/EducationalPodcast";

export const curriculumRoutes = [
  {
    path: "/curriculum/sea-discovery",
    element: (
      <SeaDiscovery
        growthData={growthData}
        pageData={pageData}
        moduleData={moduleData}
        accordionData={accordionData}
      />
    ),
  },
  {
    path: "/curriculum/twelve-weeks-plan",
    element: (
      <TwelveWeek
        accordionData={accordionData}
        reviewsData={reviewsData}
        weekAccordianData={weekAccordianData}
        weekModule={weekModule}
      />
    ),
  },
  {
    path: "/curriculum/daily-treasure-hunting",
    element: (
      <DailyTreasure
        trasuresData={trasuresData}
        gemsModule={gemsModule}
        infoCardData={infoCardData}
        leadershipModule={leadershipModule}
        leadershipData={leadershipData}
        lifeData={lifeData}
        reviewsData={reviewsData}
        accordionData={accordionData}
        whyItWorks={whyItWorks}
        strengthData={strengthData}
        personalData={personalData}
      />
    ),
  },
  {
    path: "/curriculum/legal-knowledge",
    element: (
      <LegalKnowledges
        legalData={legalData}
        legalHandBook={legalHandbookData}
        whyItMatetrs={whyItMatetrs}
        pageData={confidenceData}
        accordionData={handbookaccordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
        highlightsAccordianData={highlightsAccordianData}
        highlightsData={highlightsData}
      />
    ),
  },
  {
    path: "/curriculum/holistic-journey",
    element: (
      <HolisticJourney
        holisticBannerData={holisticBannerData}
        duiJourneyData={duiJourneyData}
        programsData={programsData}
        programsAccordianData={programsAccordianData}
        drivesData={drivesData}
        classDetails={classDetails}
        drivesChangeData={drivesChangeData}
        accordionData={handbookaccordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
      />
    ),
  },
  {
    path: "/curriculum/educational-podcast",
    element: (
      <EducationalPodcast
        podcastData={podcastData}
        educationPodcast={educationPodcast}
        faceFearData={faceFearData}
        faceFearDetail={faceFearDetail}
        surveyData={surveyData}
        sampleQuestions={sampleQuestions}
        accordionData={handbookaccordionData}
        reviewhandlebookDAta={reviewhandlebookDAta}
        podcastStructure={podcastStructure}
        podcastStructureData={podcastStructureData}
      />
    ),
  },
];