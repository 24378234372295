import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

const CustomRewardCards = ({ data }) => {
  return (
    <Grid container spacing={3}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              height: "100%",
            }}
            className="d-flex flex-column bg-lightsky radius-16 border-card-1"
          >
            <CardContent>
              <Typography
                className="text-black f-18 font-poppins fw-500"
                gutterBottom
              >
                {item.title}
              </Typography>
              <Typography className="sub_header text-darksky fw-500 font-poppins">
                {item.value.toLocaleString()}
              </Typography>
              {item.buttonText && (
                <Box mt={2}>
                  <Button
                    className="text-light f-14 font-poppins fw-500 bg-darksky"
                    sx={{ px: "20px" }}
                  >
                    {item.buttonText}
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomRewardCards;
