import VideocamIcon from "@mui/icons-material/Videocam";
import { Box } from "@mui/material";
import images from "../../../../assets/images";
import CustomAppBar from "../../../components/CustomAppBar";
import EpisodsClassesCard from "../components/EpisodsClassesCard";
import EpisodsTabs from "./components/EpisodsTabs";

const appBarData = [
  {
    title: "Podcast Learning",
    icon: <VideocamIcon />,
    xp: "400 XP",
    notifications: 7,
    profileImage: images.kimAvatar,
  },
];

export default () => {
  return (
    <Box className="d-flex justify-content-between align-items-center flex-wrap">
      <CustomAppBar {...appBarData[0]} />
      <Box className="w-100">
        <EpisodsClassesCard
          title="Immediate Consequences."
          instructor="Michael Jenkins"
          duration="7 Episodes"
        />
      </Box>

      <Box className="w-100">
        <EpisodsTabs />
      </Box>
    </Box>
  );
};
