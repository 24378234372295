import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContactDisabilityMutation } from "../../../../redux/api/apiSlice";

const GrievanceModal = ({ open, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [contactDisability, { isLoading, isError }] =
    useContactDisabilityMutation();

  const [status, setStatus] = useState("Pending");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const onSubmit = async (data) => {
    try {
      const formData = { ...data, status };
      await contactDisability(formData).unwrap();
      console.log("Grievance submitted successfully:", formData);
      toast.success("Grievance submitted successfully!");
      reset();
      setStatus("Pending");
      handleClose();
    } catch (error) {
      console.error("Error submitting grievance:", error);
      toast.error("Error submitting grievances.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="grievance-modal-title"
      aria-describedby="grievance-modal-description"
    >
      <Box
        className="all_center"
        sx={{
          height: "100vh",
        }}
        pb={2}
      >
        <Box
          sx={{
            width: 400,
            padding: 2,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 3,
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            id="grievance-modal-title"
            gutterBottom
            className="pb-2 fw-bold"
          >
            File a Grievance
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Grievance Coordinator"
              name="coordinator"
              fullWidth
              {...register("coordinator", {
                required: "Grievance Coordinator is required",
              })}
              error={!!errors.coordinator}
              helperText={errors.coordinator?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Phone"
              name="phone"
              fullWidth
              {...register("phone", { required: "Phone is required" })}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              {...register("email", { required: "Email is required" })}
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Mailing Address"
              name="address"
              fullWidth
              {...register("address", {
                required: "Mailing Address is required",
              })}
              error={!!errors.address}
              helperText={errors.address?.message}
              sx={{ marginBottom: 2 }}
            />

            {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                value={status}
                onChange={handleStatusChange}
                label="Status"
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
              </Select>
            </FormControl> */}

            <Button
              variant="contained"
              type="submit"
              className="contact_btn f-16 w-100 m-0 mb-1"
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Submit Grievance"}
            </Button>
          </form>
          {isError && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              Error submitting grievance. Please try again.
            </Typography>
          )}

          <ToastContainer />
        </Box>
      </Box>
    </Modal>
  );
};

export default GrievanceModal;
